import { render, staticRenderFns } from "./PublicSocioList.vue?vue&type=template&id=057a9355&scoped=true"
import script from "./PublicSocioList.vue?vue&type=script&lang=js"
export * from "./PublicSocioList.vue?vue&type=script&lang=js"
import style0 from "./PublicSocioList.vue?vue&type=style&index=0&id=057a9355&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057a9355",
  null
  
)

export default component.exports