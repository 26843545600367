<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title class="text-h4">
        {{ $t("t_juntaDirectiva.name") }}
        {{ juntaDirectiva.tipo.toLowerCase() }}
        {{ juntaDirectiva.numero }}
        <v-row align="center" justify="end" class="my-4">
          <v-btn @click="back()">
            <v-icon>arrow_back</v-icon>
            <span class="d-none d-sm-block"> {{ $t("back") }} </span>
          </v-btn>

          <v-btn
            class="warning ml-2"
            v-if="isAdmin || isBoardOfDirectorsManagement"
            @click="edit"
          >
            <v-icon>edit</v-icon>
            <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
          </v-btn>

          <v-btn
            class="error ml-2"
            v-if="isAdmin || isBoardOfDirectorsManagement"
            @click="deleteDialog = true"
          >
            <v-icon>delete</v-icon>
            <span class="d-none d-sm-block"> {{ $t("remove") }} </span>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="text-h6 font-weight-light">
        <span v-if="juntaDirectiva.lugar"> {{ juntaDirectiva.lugar }} , </span>
        <span v-if="juntaDirectiva.fecha">
          <!-- Remove hours if they are 00:00 -->
          {{
            formatDateWithHours(dateArrayToDate(juntaDirectiva.fecha)).slice(
              -5
            ) === "00:00"
              ? formatDateWithHours(
                  dateArrayToDate(juntaDirectiva.fecha)
                ).slice(0, -5)
              : formatDateWithHours(dateArrayToDate(juntaDirectiva.fecha))
          }}
        </span>
      </v-card-subtitle>

      <v-card-text>
        <v-tabs class="mt-4" grow background-color="transparent">
          <v-tab>{{ $t("t_juntaDirectiva.prop.imagenes") }} </v-tab>
          <v-tab>
            {{ $t("t_juntaDirectiva.prop.documentos") }}
          </v-tab>
          <v-tab-item class="pa-4">
            <v-carousel
              v-if="juntaDirectiva.fotos && juntaDirectiva.fotos.length > 0"
            >
              <v-carousel-item
                v-for="(item, i) in juntaDirectiva.fotos"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <p class="text-center">{{ item.fileName }}</p>
                <div class="img-container">
                  <img class="img" :src="item.src" alt="" />
                </div>
              </v-carousel-item>
            </v-carousel>
            <span v-else>{{ $t("t_juntaDirectiva.headers.no_fotos") }}</span>
          </v-tab-item>
          <v-tab-item class="pa-4">
            <div
              v-if="
                juntaDirectiva.documentos &&
                juntaDirectiva.documentos.length > 0
              "
            >
              <a ref="hiddenDownloader" class="d-none" />
              <v-row
                v-for="(item, i) in juntaDirectiva.documentos"
                :key="i"
                align="center"
                dense
                no-gutters
              >
                <span style="display: inline-block; width: 80px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="downloadDocument(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("download_file") }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="documentViewerTypes.includes(item.fichero.type)"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="previewDocument(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("preview_file") }}</span>
                  </v-tooltip>
                </span>
                <span
                  v-if="documentViewerTypes.includes(item.fichero.type)"
                  class="link"
                  @click="previewDocument(item)"
                  >{{ item.titulo }}</span
                >
                <span v-else class="link" @click="downloadDocument(item)">{{
                  item.titulo
                }}</span>
              </v-row>
            </div>
            <span v-else>{{
              $t("t_juntaDirectiva.headers.no_documentos")
            }}</span></v-tab-item
          >
        </v-tabs>
      </v-card-text>
    </span>
    <loading-page v-else></loading-page>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>

    <delete-dialog
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="deleteEntity"
    ></delete-dialog>
  </v-card>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import LoadingPage from "@/components/loading-page/LoadingPage";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  dateArrayToDate,
  formatDateWithHours,
} from "@/common/conversion-utils";
import { downloadFile } from "@/common/file-utils";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

const JuntaDirectivaEntityRepository = RepositoryFactory.get(
  "JuntaDirectivaEntityRepository"
);

export default {
  components: { DocumentDialog, LoadingPage, DeleteDialog },
  data() {
    return {
      loading: false,
      juntaDirectiva: {},
      deleteDialog: false,
      documentViewerTypes,
      currentDocument: null,
      currentDocumentTitle: "",
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isBoardOfDirectorsManagement"]),
    isLoading() {
      return this.loading;
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    formatDateWithHours,
    dateArrayToDate,
    _fetchData() {
      this.loading = true;
      JuntaDirectivaEntityRepository.get(this.$route.params.id)
        .then((response) => {
          const promises = response.fotos.map((item) => this.getImageUrl(item));
          return Promise.all(promises).then(() => {
            this.juntaDirectiva = response;
          });
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    previewDocument(item) {
      this.currentDocument = JuntaDirectivaEntityRepository.getDocumentFile(
        this.juntaDirectiva.id,
        item.id
      );
      this.currentDocumentTitle = item.fichero.fileName;
    },
    downloadDocument(item) {
      JuntaDirectivaEntityRepository.getDocumentFile(
        this.juntaDirectiva.id,
        item.id
      ).then((response) => {
        downloadFile(
          response,
          item.fichero.fileName,
          this.$refs.hiddenDownloader
        );
      });
    },
    getImageUrl(item) {
      if (!item.uuid) return Promise.resolve();

      return JuntaDirectivaEntityRepository.getImage(
        this.$route.params.id,
        item.uuid
      )
        .then((savedBlob) => {
          item.src = URL.createObjectURL(savedBlob);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    back() {
      this.$router.push({
        name:
          this.isAdmin || this.isBoardOfDirectorsManagement
            ? "JuntaDirectiva List"
            : "Public JuntaDirectiva List",
        params: { backAction: true },
      });
    },
    edit() {
      if (!(this.isAdmin || this.isBoardOfDirectorsManagement)) return;
      this.$router.push({
        name: "JuntaDirectiva FormForm",
        params: { id: this.juntaDirectiva.id, backPrevious: true },
      });
    },
    deleteEntity() {
      if (!(this.isAdmin || this.isBoardOfDirectorsManagement)) return;
      this.loading = true;
      return JuntaDirectivaEntityRepository.delete(this.juntaDirectiva.id)
        .then(() => {
          this.deleteDialog = false;
          this.$router.push({ name: "JuntaDirectiva List" });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.img-container {
  text-align: center;
}
.img {
  max-width: 100%;
  height: 500px;
  object-fit: contain;
}
</style>
