<template>
  <v-card flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirect"
          ></debounced-text-field>
        </v-col>
        <v-col
          v-if="isAdmin || isSpFinances"
          cols="12"
          order="1"
          order-md="2"
          class="text-right col-md-auto"
        >
          <v-btn color="success ml-2" :to="{ name: 'Presupuesto FormCreate' }">
            <v-icon>add</v-icon>
            <span class="d-none d-sm-block"> {{ $t("new") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="mt-4">
      <span v-if="!loading">
        <div v-if="items && items.length > 0">
          <a ref="hiddenDownloader" class="d-none" />
          <div v-for="(item, i) in items" :key="i">
            <v-row align="center" dense no-gutters>
              <v-col cols="12" md="4">
                <v-row align="center" justify="center" dense no-gutters>
                  <v-col cols="10">
                    {{ item.titulo }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row align="center" justify="center" dense no-gutters>
                  <v-col cols="5" md="3" class="text-left font-weight-bold">
                    {{ $t("t_presupuesto.prop.ano") }}:
                  </v-col>
                  <v-col cols="5" md="7">
                    {{ item.ano }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row align="center" justify="center" dense no-gutters>
                  <v-col>
                    <span style="display: inline-block; width: 80px">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="downloadDocument(item)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon color="primary">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("download_file") }}</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="documentViewerTypes.includes(item.fichero.type)"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="previewDocument(item)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon color="primary">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("preview_file") }}</span>
                      </v-tooltip>
                    </span>
                    <span
                      v-if="documentViewerTypes.includes(item.fichero.type)"
                      class="link"
                      @click="previewDocument(item)"
                      >{{ item.titulo }}</span
                    >
                    <span v-else class="link" @click="downloadDocument(item)">{{
                      item.titulo
                    }}</span>
                  </v-col>
                  <v-col v-if="isAdmin || isSpFinances" cols="1">
                    <v-btn icon @click="editEntity(item)">
                      <v-icon color="warning"> edit </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="isAdmin || isSpFinances" cols="1">
                    <v-btn icon @click="showDeleteDialog(item)"
                      ><v-icon color="error">delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider v-if="i < items.length - 1" class="my-2"></v-divider>
          </div>
        </div>
        <div
          v-else
          class="text-h5"
          style="align-content: center; justify-content: center; display: flex"
        >
          {{ $t("t_presupuesto.headers.no_presupuestos") }}
        </div>
      </span>
      <loading-page v-else></loading-page>
    </v-card-text>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>

    <delete-dialog
      :dialog="dialog"
      @cancel="dialog = false"
      @submit="deleteEntity()"
    ></delete-dialog>
  </v-card>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";

import DeleteDialog from "@/components/modal_dialog/DeleteDialog";

import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { downloadFile } from "@/common/file-utils";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

const PresupuestoEntityRepository = RepositoryFactory.get(
  "PresupuestoEntityRepository"
);

export default {
  name: "presupuestoListList",
  components: {
    DocumentDialog,
    LoadingPage,
    DeleteDialog,
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      search: null,
      dialog: false,
      selected: null,
      totalItems: 0,
      loading: false,
      documentViewerTypes,
      currentDocument: null,
      currentDocumentTitle: "",
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isSpFinances"]),
  },
  watch: {},
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          search: this.search,
        },
      };
      PresupuestoEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.search = this.search || undefined;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: this.$route.name,
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Presupuesto FormForm",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    previewDocument(item) {
      this.currentDocument = PresupuestoEntityRepository.getFile(item.id);
      this.currentDocumentTitle = item.fichero.fileName;
    },
    downloadDocument(item) {
      PresupuestoEntityRepository.getFile(item.id).then((response) => {
        downloadFile(
          response,
          item.fichero.fileName,
          this.$refs.hiddenDownloader
        );
      });
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.dialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.dialog = false;
    },
    deleteEntity() {
      PresupuestoEntityRepository.delete(this.selected.id)
        .then(() => this.getItems())
        .finally(() => this.closeDeleteDialog());
    },
  },
};
</script>
