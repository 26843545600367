<template>
  <div>
    <v-row class="mb-2">
      <v-col>
        <v-btn color="primary" @click="addDocumentDialog()">
          <v-icon>mdi-file-document-plus</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("t_socio.actions.add_document") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="documentos && documentos.length > 0">
      <v-row
        v-for="(item, i) in documentos"
        :key="i"
        align="center"
        dense
        no-gutters
      >
        <v-col cols="12" md="5">
          <span style="display: inline-block; width: 80px">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="downloadDocument(item.fichero)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="primary">mdi-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("download_file") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="
                item.fichero && documentViewerTypes.includes(item.fichero.type)
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="previewDocument(item.fichero)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="primary">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("preview_file") }}</span>
            </v-tooltip>
          </span>
          <span
            v-if="
              item.fichero && documentViewerTypes.includes(item.fichero.type)
            "
            class="link"
            @click="previewDocument(item.fichero)"
            >{{ item.data.titulo }}</span
          >
          <span v-else class="link" @click="downloadDocument(item.fichero)">{{
            item.data.titulo
          }}</span>
        </v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="6" md="3" class="text-left font-weight-bold">
              {{ $t("t_socio.documento.tipo") }}:
            </v-col>
            <v-col cols="6" md="9">
              {{ item.data.tipo.nombre }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="6" md="3" class="text-left font-weight-bold">
              {{ $t("t_socio.documento.fecha") }}:
            </v-col>
            <v-col cols="6" md="9">
              {{ item.data.fecha | dateFormat }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" class="mt-2 mt-md-0">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="orange"
                @click="() => editDocumentDialog(i)"
              >
                edit
              </v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="error"
                @click="() => showDeleteDialog(i)"
              >
                delete
              </v-icon>
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <span v-else>{{ $t("t_socio.headers.no_documentos") }}</span>
    <socio-form-document-dialog
      :content="currentDocument"
      :is-editing-document="isEditingDocument"
      @save-document="saveDocument"
      @preview-document="previewDocument"
    >
    </socio-form-document-dialog>
    <delete-dialog
      v-if="deleteDialog"
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="deleteFunction"
    ></delete-dialog>
  </div>
</template>

<script>
import { downloadFile, uploadFile } from "@/common/file-utils";
import { getTodayDateAsArray } from "@/common/conversion-utils";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog.vue";
import SocioFormDocumentDialog from "@/modules/socio/components/socioFormDocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

export default {
  name: "socioFormDocument",
  components: { SocioFormDocumentDialog, DeleteDialog },
  props: {
    content: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      documentos: [],
      currentDocument: null,
      isEditingDocument: false,
      deleteDialog: false,
      deleteFunction: null,
      documentViewerTypes,
    };
  },
  created() {
    if (this.content) {
      this.documentos = this.content;
    }
  },
  methods: {
    saveDocument(document) {
      this.currentDocument = document;
      try {
        if (this.currentDocument) {
          // Rename original document
          this.currentDocument.fichero = new File(
            [this.currentDocument.fichero],
            this.currentDocument.data.titulo +
              this.currentDocument.fichero.name.match(/\.[^/.]+$/)[0],
            {
              type: this.currentDocument.fichero.type,
            }
          );
          // Upload document
          uploadFile(this.currentDocument.fichero).then((response) => {
            this.currentDocument.data.fichero = response;
          });
          // Update documents array
          if (this.isEditingDocument) {
            this.documentos[this.currentDocumentIndex] = this.currentDocument;
          } else {
            this.documentos.push(this.currentDocument);
          }
        }
      } catch (e) {
        console.error(e);
        if (this.isEditingDocument) {
          this.documentos[this.currentDocumentIndex].data.fichero = null;
        }
      }

      // Sort documents by descending date
      this.documentos.sort((a, b) => {
        const dateA = new Date(a.data.fecha);
        const dateB = new Date(b.data.fecha);
        return dateB - dateA;
      });
      this.$emit("update-documents", this.documentos);
    },
    addDocumentDialog() {
      this.currentDocument = {
        fichero: null,
        data: {
          titulo: "",
          fecha: getTodayDateAsArray(),
          tipo: null,
          fichero: null,
        },
      };
      this.isEditingDocument = false;
    },
    editDocumentDialog(index) {
      this.currentDocument = structuredClone(this.documentos[index]);
      this.currentDocumentIndex = index;
      this.isEditingDocument = true;
    },
    deleteDocument(index) {
      this.documentos.splice(index, 1);
      this.closeDeleteDialog();
    },
    showDeleteDialog(index) {
      this.deleteFunction = () => this.deleteDocument(index);
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    previewDocument(file) {
      console.log(file);
      this.$emit("preview-document", file);
    },
    downloadDocument(file) {
      this.$emit("download-document", file);
    },
  },
};
</script>
