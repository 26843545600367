<template>
  <v-container fluid>
    <v-card v-if="items" flat class="transparent">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            order="2"
            order-md="1"
            class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
          >
            <debounced-text-field
              v-model="search"
              append-icon="search"
              class="d-md-inline-block"
              dense
              hide-details
              :label="$t('search')"
              @input="redirectOnFilterChange"
            ></debounced-text-field>
          </v-col>
          <v-col order="1" order-md="2" class="text-right col-md-auto">
            <v-btn color="success ml-2" :to="{ name: 'Grupo FormCreate' }">
              <v-icon>add</v-icon>
              <span class="d-none d-sm-block"> {{ $t("new") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-col cols="12" md="12">
          <v-data-table
            class="transparent"
            :headers="headers"
            :items="items"
            :search="search"
            :options="entitiesPage"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="tableFooterProps"
            @update:options="redirectOnTableChange"
          >
            <template v-slot:item="{ item }">
              <tr @click="entityDetail(item)">
                <td>{{ item.name }}</td>
                <td
                  style="
                    max-width: 1px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  <span v-if="item.socios.length > 0">
                    <v-chip
                      v-for="(socio, index) in item.socios"
                      :key="index"
                      class="mr-2"
                    >
                      {{ socio.nombre + " " + socio.apellidos }}
                    </v-chip>
                  </span>
                  <span v-else> — </span>
                </td>
                <td>
                  <v-icon color="primary" @click.stop="entityDetail(item)">
                    description
                  </v-icon>
                  <v-icon
                    color="warning"
                    v-if="isLogged"
                    @click.stop="editEntity(item)"
                  >
                    edit
                  </v-icon>
                  <v-icon
                    color="error"
                    v-if="isLogged"
                    @click.stop="showDeleteDialog(item)"
                  >
                    delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-card-text>
    </v-card>
    <delete-dialog
      :dialog="dialog"
      @cancel="dialog = false"
      @submit="deleteEntity()"
    ></delete-dialog>
  </v-container>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";

import DeleteDialog from "@/components/modal_dialog/DeleteDialog";

import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";

import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import tableFooterProps from "@/common/table-footer-props";

import displayManyRelationship from "@/common/DisplayManyRelationships";
import reportRequestFn from "@/common/ReportRequest";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const GrupoEntityRepository = RepositoryFactory.get("GrupoEntityRepository");

export default {
  name: "grupoListList",
  components: {
    DeleteDialog,
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      search: null,
      dialog: false,
      selected: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    headers() {
      return [
        {
          text: this.$t("t_grupo.prop.name"),
          value: "name",
          width: "20%",
        },
        {
          text: this.$t("t_grupo.prop.socios"),
          value: "socios",
          width: "70%",
        },
        { text: "", sortable: false, value: "action", width: "110px" },
      ];
    },
  },
  watch: {},
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    if (JSON.stringify(this.$route.query) != "{}") this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          search: this.search,
          sort: this.$route.query.sort,
          size: this.entitiesPage.itemsPerPage,
        },
      };
      GrupoEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: "Grupo List",
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Grupo FormDetail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Grupo FormForm",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      query.search = this.search || undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.dialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.dialog = false;
    },
    deleteEntity() {
      GrupoEntityRepository.delete(this.selected.id)
        .then(() => this.getItems())
        .finally(() => this.closeDeleteDialog());
    },
    displayManyRelationship,
  },
};
</script>

<style scoped>
.v-chip:before {
  background-color: transparent !important;
}
</style>
