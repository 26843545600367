<template>
  <v-card v-if="items" flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirectOnFilterChange"
          ></debounced-text-field>
        </v-col>
        <v-col cols="12" order="1" order-md="2" class="text-right col-md-auto">
          <v-btn
            color="success ml-2"
            :to="{ name: 'TipoDocumento FormCreate' }"
          >
            <v-icon>add</v-icon>
            <span class="d-none d-sm-block"> {{ $t("new") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-col cols="12" md="4" offset-md="4">
        <v-data-table
          class="transparent"
          :headers="headers"
          :items="items"
          :search="search"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="tableFooterProps"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon color="warning" @click.stop="editEntity(item)">
              edit
            </v-icon>
            <v-icon color="error" @click.stop="showDeleteDialog(item)">
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card-text>
    <delete-dialog
      :dialog="dialog"
      @cancel="dialog = false"
      @submit="deleteEntity()"
    ></delete-dialog>
  </v-card>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";

import DeleteDialog from "@/components/modal_dialog/DeleteDialog";

import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";

import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import tableFooterProps from "@/common/table-footer-props";

import RepositoryFactory from "@/repositories/RepositoryFactory";
const TipoDocumentoEntityRepository = RepositoryFactory.get(
  "TipoDocumentoEntityRepository"
);

export default {
  name: "tipoDocumentoList",
  components: {
    DeleteDialog,
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      search: null,
      dialog: false,
      selected: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("t_tipoDocumento.prop.nombre"),
          value: "nombre",
          align: "center",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  watch: {},
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    if (JSON.stringify(this.$route.query) != "{}") this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          search: this.search,
          sort: this.$route.query.sort,
          size: this.entitiesPage.itemsPerPage,
        },
      };
      TipoDocumentoEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: "TipoDocumento List",
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "TipoDocumento Form",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      query.search = this.search || undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.dialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.dialog = false;
    },
    deleteEntity() {
      TipoDocumentoEntityRepository.delete(this.selected.id)
        .then(() => this.getItems())
        .finally(() => this.closeDeleteDialog());
    },
  },
};
</script>
