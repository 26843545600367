<template>
  <v-card v-if="items" flat class="transparent">
    <v-card-title class="pb-0">
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirectOnFilterChange"
          ></debounced-text-field>
        </v-col>
        <v-col cols="12" order="1" order-md="2" class="text-right col-md-auto">
          <v-btn color="primary ml-2" @click="paymentsReport()">
            <v-icon class="mr-1">mdi-account-cash</v-icon>
            <span class="d-none d-md-block">
              {{ $t("t_socio.actions.generate_payments_report") }}
            </span>
          </v-btn>
          <v-btn color="primary ml-2" @click="reportRequest()">
            <v-icon>import_export</v-icon>
            <span class="d-none d-md-block">
              {{ $t("t_socio.actions.generate_members_report") }}
            </span>
          </v-btn>
          <v-btn
            v-if="isAdmin || isPartnerManagement"
            color="success ml-2"
            :to="{ name: 'Socio FormCreate' }"
          >
            <v-icon>add</v-icon>
            <span class="d-none d-md-block"> {{ $t("new") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row align="center" justify="end" dense no-gutters>
        <v-col cols="12" md="3">
          <v-row align="center" justify="end" class="my-2">
            <span>{{ $t("t_socio.status_filter.show") }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text style="text-transform: none"
                  >{{ $t(statusFilter.text) }}
                  <v-icon right> mdi-chevron-down </v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="(option, index) in statusFilterOptions"
                  :key="index"
                  @click="selectStatusFilter(option)"
                >
                  <v-list-item-title>{{ $t(option.text) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-data-table
            class="row-height row-pointer transparent"
            :headers="headers"
            :items="items"
            :search="search"
            :options="entitiesPage"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="tableFooterProps"
            @click:row="entityDetail"
            @update:options="redirectOnTableChange"
          >
            <template v-slot:[`item.mesNac`]="{ item }">
              <span v-if="item.mesNac && item.diaNac">
                {{ item.mesNac }}/{{ item.diaNac }}
              </span>
            </template>

            <template v-slot:[`item.fechaBaja`]="{ item }">
              <v-tooltip top :color="item.fechaBaja ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="item.fechaBaja ? 'error' : 'success'"
                  >
                    {{
                      item.fechaBaja
                        ? "mdi-account-cancel"
                        : "mdi-account-check"
                    }}
                  </v-icon>
                </template>
                <span v-if="item.fechaBaja">
                  {{ $t("t_socio.messages.deactivated_since")
                  }}{{ item.fechaBaja | dateFormat }}
                </span>
                <span v-else-if="item.fechaAlta">
                  {{ $t("t_socio.messages.activated_since")
                  }}{{ item.fechaAlta | dateFormat }}
                </span>
                <span v-else> {{ $t("t_socio.messages.activated") }} </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col></v-row
      >
    </v-card-text>
  </v-card>
</template>

<script>
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import tableFooterProps from "@/common/table-footer-props";

import reportRequestFn from "@/common/ReportRequest";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapAuthGetter } from "@/common/mapAuthGetter";
const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "socioListList",
  components: {
    DebouncedTextField,
  },
  data() {
    return {
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      items: [],
      loading: false,
      search: null,
      tableFooterProps,
      totalItems: 0,
      selected: null,
      deleteDialog: false,
      statusFilter: null,
      statusFilterOptions: [
        {
          text: "t_socio.status_filter.all",
          value: "",
        },
        {
          text: "t_socio.status_filter.active",
          value: "fechaBaja:NULL",
        },
        {
          text: "t_socio.status_filter.inactive",
          value: "fechaBaja:NOT_NULL",
        },
      ],
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isPartnerManagement"]),
    headers() {
      const columns = [
        {
          text: this.$t("t_socio.prop.numero"),
          value: "numero",
          width: "100px",
        },
        {
          text: this.$t("t_socio.prop.dni"),
          value: "dni",
          width: "1px",
        },
        {
          text: this.$t("t_socio.prop.apellidos"),
          value: "apellidos",
        },
        {
          text: this.$t("t_socio.prop.nombre"),
          value: "nombre",
        },
        {
          text: this.$t("t_socio.prop.telefono"),
          value: "telefono",
          width: "1px",
        },
        {
          text: this.$t("t_socio.prop.email"),
          value: "email",
          width: "1px",
        },
        {
          text: this.$t("t_socio.prop.fechaNac"),
          value: "mesNac",
          align: "end",
          width: "1px",
        },
        {
          text: this.$t("t_socio.prop.anoNac"),
          value: "anoNac",
          align: "start",
          width: "1px",
        },
      ];

      // Show the status column only if all members are shown
      if (this.statusFilter.value === "") {
        columns.push({
          text: this.$t("t_socio.prop.estado"),
          sortable: false,
          value: "fechaBaja",
        });
      }

      return columns;
    },
  },
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    if (this.$route.query.filters) {
      this.statusFilter = this.statusFilterOptions.find(
        (el) => el.value === this.$route.query.filters
      );
    } else {
      this.statusFilter = {
        text: "t_socio.status_filter.active",
        value: "fechaBaja:NULL",
      };
    }
    if (JSON.stringify(this.$route.query) != "{}") this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          search: this.search,
          sort: this.$route.query.sort,
          size: this.entitiesPage.itemsPerPage,
          filters: this.$route.query.filters,
        },
      };
      SocioEntityRepository.getAll(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: "Socio List",
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Socio FormDetail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      if (query.sort === "mesNac,asc") {
        query.sort = "mesNac,asc&sort=diaNac,asc";
      } else if (query.sort === "mesNac,desc") {
        query.sort = "mesNac,desc&sort=diaNac,desc";
      }
      query.search = this.search || undefined;
      query.filters = this.statusFilter.value;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    paymentsReport() {
      SocioEntityRepository.getPaymentsReport().then((data) => {
        const fileLink = document.createElement("a");
        fileLink.href = window.URL.createObjectURL(new Blob([data]));
        fileLink.setAttribute("download", "Pagamentos.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    reportRequest() {
      reportRequestFn(SocioEntityRepository, "Persoas socias");
    },
    selectStatusFilter(option) {
      this.statusFilter = option;
      this.redirectOnFilterChange();
    },
  },
};
</script>
<style>
.v-data-table.row-height td {
  height: 18px !important;
  font-size: 16px !important;
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
