var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"5"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1),_c('v-btn',{staticClass:"success ml-2",attrs:{"disabled":!_vm.validForm},on:{"click":function($event){return _vm.save()}}},[_c('v-icon',[_vm._v("save")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("user_management.save"))+" ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-text-field',{attrs:{"autocomplete":"username","autofocus":"","name":"login","prepend-inner-icon":"person","type":"text","label":_vm.$t('user_management.login'),"rules":[
                  (v) => !!v || _vm.$t('user_management.usernameRequired'),
                ],"required":""},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", $$v)},expression:"user.login"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"autocomplete":"given-name","name":"firstname","prepend-inner-icon":"notes","type":"text","label":_vm.$t('user_management.firstName'),"rules":[
                  (v) => !!v || _vm.$t('user_management.firstNameRequired'),
                ],"required":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"autocomplete":"family-name","name":"lastname","prepend-inner-icon":"notes","type":"text","label":_vm.$t('user_management.lastName'),"rules":[
                  (v) => !!v || _vm.$t('user_management.lastNameRequired'),
                ],"required":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"autocomplete":"email","name":"email","prepend-inner-icon":"email","type":"email","label":_vm.$t('user_management.email'),"rules":_vm.emailRules,"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.authoritiesDisp,"label":_vm.$t('user_management.authorities'),"menu-props":{ offsetY: true },"multiple":"","chips":"","rules":_vm.authorityRules},model:{value:(_vm.user.authorities),callback:function ($$v) {_vm.$set(_vm.user, "authorities", $$v)},expression:"user.authorities"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }