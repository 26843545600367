import { HTTP } from "@/common/http-common";
import Logger from "js-logger";

const logger = Logger.get("logger");
const RESOURCE_NAME = "account";
const AUTHENTICATION_RESOURCE_NAME = "authenticate";
const RESET_PASSWORD_RESOURCE_NAME = `${RESOURCE_NAME}/reset_password`;

const ACTIVATE_RESOURCE_NAME = "activate";

export default {
  async getUser() {
    return (await HTTP.get(RESOURCE_NAME)).data;
  },

  async login(credentials) {
    return (
      await HTTP.post(AUTHENTICATION_RESOURCE_NAME, credentials, {
        params: { remember_me: credentials.remember_me },
      })
    ).data;
  },

  async isAuthenticated() {
    return (await HTTP.get(AUTHENTICATION_RESOURCE_NAME)).data;
  },
  async resetPasswordInit(login) {
    try {
      return (
        await HTTP.post(`${RESET_PASSWORD_RESOURCE_NAME}/init`, {
          login: login,
        })
      ).data;
    } catch (err) {
      logger.error("Error starting reset password", login);
      throw err;
    }
  },

  async checkPasswordResetKey(key) {
    return (
      await HTTP.get(`${RESET_PASSWORD_RESOURCE_NAME}/check_key`, {
        params: { key: key },
      })
    ).data;
  },

  async resetPasswordFinish(keyAndPassword) {
    return (
      await HTTP.post(`${RESET_PASSWORD_RESOURCE_NAME}/finish`, keyAndPassword)
    ).data;
  },

  async activateUser(key) {
    return (await HTTP.get(ACTIVATE_RESOURCE_NAME, { params: { key: key } }))
      .data;
  },

  async changeLanguage(login, newLang) {
    return (
      await HTTP.put(`${RESOURCE_NAME}/${login}/language`, newLang, {
        headers: { "Content-Type": "text/plain" },
      })
    ).data;
  },

  async changePassword(currentPassword, newPassword) {
    return (
      await HTTP.post(`${RESOURCE_NAME}/change_password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    ).data;
  },
};
