import socioListList from "./components/socioListList";
import socioFormForm from "./components/socioFormForm";
import socioFormDetail from "./components/socioFormDetail";
import PublicSocioList from "./components/PublicSocioList.vue";

const routes = [
  {
    path: "/socios",
    name: "Public Socio List",
    component: PublicSocioList,
    meta: {
      userIsLogged: true,
      label: "t_socio.headers.socioList",
    },
  },
  {
    path: "/socios-admin",
    name: "Socio List",
    component: socioListList,
    meta: {
      authority: ["ROLE_ADMIN", "PARTNER_MANAGEMENT", "PARTNER_READONLY"],
      label: "t_socio.headers.socioList",
    },
  },
  {
    path: "/socios/:id/editar",
    name: "Socio FormForm",
    component: socioFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "PARTNER_MANAGEMENT"],
      label: "t_socio.headers.socioForm",
    },
  },
  {
    path: "/socios/novo",
    name: "Socio FormCreate",
    component: socioFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "PARTNER_MANAGEMENT"],
      label: "t_socio.headers.socioForm",
    },
  },
  {
    path: "/socios/:id(\\d+)",
    name: "Socio FormDetail",
    component: socioFormDetail,
    meta: {
      userIsLogged: true,
      label: "t_socio.headers.socioFormDetail",
    },
  },
];

export default routes;
