<template>
  <v-card flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirect"
          ></debounced-text-field>
        </v-col>
        <v-col
          v-if="isAdmin || isAssemblyManagement"
          cols="12"
          order="1"
          order-md="2"
          class="text-right col-md-auto"
        >
          <v-btn
            class="warning ml-2"
            :to="{ name: 'Asamblea List', query: { sort: 'fecha,desc' } }"
          >
            <v-icon class="material-icons details-close-button">edit</v-icon>
            <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <span v-if="!loading">
        <div v-if="asambleas.length > 0">
          <v-card
            v-for="(item, idx) in asambleas"
            :key="idx"
            class="my-4"
            @click="goToAsambleaDetail(item)"
          >
            <v-card-title>
              {{ $t("t_asamblea.name") }} {{ item.tipo.toLowerCase() }}
              {{ item.numero }}
            </v-card-title>

            <v-card-subtitle>
              <span v-if="item.lugar"> {{ item.lugar }} , </span>
              <!-- Remove hours if they are 00:00 -->
              {{
                formatDateWithHours(dateArrayToDate(item.fecha)).slice(-5) ===
                "00:00"
                  ? formatDateWithHours(dateArrayToDate(item.fecha)).slice(
                      0,
                      -5
                    )
                  : formatDateWithHours(dateArrayToDate(item.fecha))
              }}
            </v-card-subtitle>

            <v-card-actions class="actions">
              <v-btn
                text
                small
                class="readMoreBtn"
                @click.stop="goToAsambleaDetail(item)"
              >
                {{ $t("readMore") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div
          v-else
          class="text-h5"
          style="align-content: center; justify-content: center; display: flex"
        >
          {{ $t("t_asamblea.headers.no_asambleas") }}
        </div>
      </span>
      <span v-else> <loading-page></loading-page></span>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  dateArrayToDate,
  formatDateWithHours,
} from "@/common/conversion-utils";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const AsambleaEntityRepository = RepositoryFactory.get(
  "AsambleaEntityRepository"
);

export default {
  name: "PublicAsambleaList",
  components: { DebouncedTextField, LoadingPage },
  data() {
    return {
      asambleas: [],
      loading: false,
      search: null,
    };
  },
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    this._fetchData();
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isAssemblyManagement"]),
  },
  methods: {
    formatDateWithHours,
    dateArrayToDate,
    _fetchData() {
      this.loading = true;
      const options = {
        params: {
          search: this.search,
        },
      };
      AsambleaEntityRepository.getAll(options)
        .then((res) => {
          this.asambleas = res.content;
        })
        .finally(() => (this.loading = false));
    },
    goToAsambleaDetail(item) {
      this.$router.push({
        name: "Public Asamblea Detail",
        params: { id: item.id, backPrevious: true },
      });
    },
    redirect() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.search = this.search || undefined;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: this.$route.name,
            query: query,
          })
          .then(() => this._fetchData());
      }
    },
  },
};
</script>
<style scoped>
.actions {
  justify-content: end;
}
.readMoreBtn {
  text-transform: none;
}
</style>
