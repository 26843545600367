<template>
  <v-row
    align="center"
    justify="center"
    style="
      background-color: #5f4770;
      margin-right: 1px !important;
      margin-left: 1px !important;
    "
  >
    <v-menu offset-y transition="slide-y-transition" class="dropdown-content">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" :class="{ 'button-on-collapse': collapsed }" tile>
          {{ $t("menu.project.title") }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list color="primary">
        <v-list-item :to="{ name: 'Presentación' }">
          <v-list-item-title>
            {{ $t("menu.project.presentation") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Instalacións' }">
          <v-list-item-title>
            {{ $t("menu.project.facilities") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Servizos' }">
          <v-list-item-title>
            {{ $t("menu.project.services") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Ideario' }">
          <v-list-item-title>
            {{ $t("menu.project.ideology") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu offset-y transition="slide-y-transition" class="dropdown-content">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" :class="{ 'button-on-collapse': collapsed }" tile>
          {{ $t("menu.association.title") }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list color="primary">
        <v-list-item :to="{ name: 'Xestión de idades' }">
          <v-list-item-title>
            {{ $t("menu.association.age_management") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Como asociarse' }">
          <v-list-item-title>
            {{ $t("menu.association.associate") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Organización' }">
          <v-list-item-title>
            {{ $t("menu.association.organization") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Documento List', query: { sort: 'fecha,desc' } }"
        >
          <v-list-item-title :class="isLogged ? '' : 'disabled'">
            {{ $t("menu.association.documentation") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{
            name:
              isAdmin || isPartnerManagement || isPartnerReadonly
                ? 'Socio List'
                : 'Public Socio List',
          }"
        >
          <v-list-item-title :class="isLogged ? '' : 'disabled'">
            {{ $t("menu.association.partners") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Contactar' }">
          <v-list-item-title>
            {{ $t("menu.association.contact") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu offset-y transition="slide-y-transition" class="dropdown-content">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" :class="{ 'button-on-collapse': collapsed }" tile>
          {{ $t("menu.finances.title") }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list color="primary">
        <v-list-item :to="{ name: 'Prezos' }">
          <v-list-item-title>
            {{ $t("menu.finances.prices") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Presupuesto List' }">
          <v-list-item-title :class="isLogged ? '' : 'disabled'">
            {{ $t("menu.finances.budget") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Contabilidade' }">
          <v-list-item-title :class="isLogged ? '' : 'disabled'">
            {{ $t("menu.finances.accounting") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu offset-y transition="slide-y-transition" class="dropdown-content">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" :class="{ 'button-on-collapse': collapsed }" tile>
          {{ $t("menu.activities.title") }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list color="primary">
        <v-list-item :to="{ name: 'Public Evento List' }">
          <v-list-item-title>
            {{ $t("menu.activities.public_events") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{
            name: 'Partner Evento List',
          }"
        >
          <v-list-item-title :class="isLogged ? '' : 'disabled'">
            {{ $t("menu.activities.private_events") }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'Public Asamblea List' }">
          <v-list-item-title :class="isLogged ? '' : 'disabled'">
            {{ $t("menu.activities.assemblies") }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'Public JuntaDirectiva List' }">
          <v-list-item-title
            :class="
              isAdmin ||
              isBoardOfDirectorsReadonly ||
              isBoardOfDirectorsManagement
                ? ''
                : 'disabled'
            "
          >
            {{ $t("menu.activities.board_of_directors") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="isAdmin"
      offset-y
      transition="slide-y-transition"
      class="dropdown-content"
    >
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" :class="{ 'button-on-collapse': collapsed }" tile>
          {{ $t("menu.management.title") }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list color="primary">
        <v-list-item :to="{ name: 'TipoDocumento List' }">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.tipo_documento")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'TipoDocumentoSocio List' }">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.tipo_documento_socio")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Email Form' }">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.emails")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Permiso List' }">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.permiso")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Manage Password' }">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.passwords")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Grupo List' }">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.groups")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      active-class="hide-active"
      text
      v-show="!isLogged"
      :to="{ name: 'Login' }"
      tile
    >
      <v-icon>mdi-login</v-icon>
    </v-btn>
    <v-menu v-show="isLogged" offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          v-show="isLogged"
          :class="{ 'button-on-collapse': collapsed }"
          tile
        >
          <v-icon large>account_circle</v-icon>
        </v-btn>
      </template>
      <v-list v-show="isLogged" color="primary">
        <v-list-item two-line v-show="isLogged">
          <v-list-item-avatar>
            <v-icon class="user-avatar" size="50">account_circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.login }}</v-list-item-title>
            <v-list-item-subtitle>{{ loggedAs }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list color="primary">
          <v-divider />
          <v-list-item
            v-show="isLogged"
            @click="$router.push({ name: 'ChangePassword' })"
          >
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.change_password")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item v-show="isLogged" @click="logout()">
            <v-list-item-content>
              <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list>
    </v-menu>
  </v-row>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "MenuBarItems",
  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      user: auth.getUser(),
    };
  },
  mounted() {
    window.addEventListener("load", () => this.$emit("bar-items-loaded"));
  },
  beforeDestroy() {
    window.removeEventListener("load", this.resizeHandler);
  },
  computed: {
    ...mapAuthGetter([
      "isAdmin",
      "isLogged",
      "isPartnerManagement",
      "isPartnerReadonly",
      "isBoardOfDirectorsReadonly",
      "isBoardOfDirectorsManagement",
    ]),
    loggedAs() {
      return this.isLogged
        ? this.$t("menu.loggedAs") +
            (this.isAdmin
              ? this.$t("menu.admin")
              : this.$t("menu.registeredUser"))
        : "";
    },
  },
  methods: {
    logout() {
      auth.logout().then(
        this.$router.push({ name: "Presentación" }).catch((err) => {
          //Do nothing if navigating to current route, otherwise throw error
          if (!err.name === "NavigationDuplicated") {
            throw err;
          }
        })
      );
    },
  },
};
</script>
<style scoped>
.button-on-collapse {
  display: list-item;
  padding: 5px;
  justify-content: left;
}
.v-menu__content {
  border-radius: 0px !important;
}
.v-btn {
  color: white;
  font-size: medium;
  font-weight: bold;
}
.v-list {
  text-align: center;
  max-height: 70vh;
  overflow-y: auto;
}
.v-list-item {
  color: rgba(255, 255, 255, 0.877) !important;
}
.v-list-item__title {
  font-weight: bold;
  font-size: large !important;
}
.v-list-item__subtitle {
  color: rgba(255, 255, 255, 0.877) !important;
}
.user-avatar {
  color: rgba(255, 255, 255, 0.877);
}
.dropdown-content {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
