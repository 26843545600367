import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const localeGl = require.context("../locale/gl", true, /\.json$/);

const messages = {
  GL: {},
};

localeGl.keys().forEach((filename) => {
  Object.keys(localeGl(filename)).forEach((key) => {
    messages.GL[key] = localeGl(filename)[key];
  });
});

const i18n = new VueI18n({
  locale: "GL", // set locale
  fallbackLocale: "GL", // set fallback locale
  messages, // set locale messages
});

export default i18n;
