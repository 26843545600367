<template>
  <v-card v-if="items" flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="6"
          xl="8"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirect"
          ></debounced-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" offset-md="4">
          <v-data-table
            class="transparent"
            :headers="headers"
            :items="items"
            :search="search"
            :server-items-length="totalItems"
            :loading="loading"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th v-for="(h, i) in headers" :key="i">
                    <span :class="h.class" class="text-h5">{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                :class="{ 'row-pointer': item.id === userId }"
                @click="entityDetail(item)"
              >
                <td>{{ item.apellidos }}</td>
                <td>{{ item.nombre }}</td>
                <td>
                  <v-icon
                    v-if="userId === item.id"
                    color="primary"
                    @click.stop="entityDetail(item)"
                  >
                    description
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";

import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "PublicSocioList",
  components: {
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      loading: false,
      search: null,
      totalItems: 0,
    };
  },
  computed: {
    ...mapAuthGetter(["userId"]),
    headers() {
      return [
        {
          text: this.$t("t_socio.prop.apellidos"),
          value: "apellidos",
          sortable: false,
          class: "custom-header",
        },
        {
          text: this.$t("t_socio.prop.nombre"),
          value: "nombre",
          sortable: false,
          class: "custom-header",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          search: this.search,
          sort: "apellidos,asc",
          filters: "fechaBaja:NULL",
        },
      };
      SocioEntityRepository.getAllPublic(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.search = this.search || undefined;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: this.$route.name,
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (this.userId === entity.id && selection.length === 0) {
        this.$router.push({
          name: "Socio FormDetail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
  },
};
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
.custom-header {
  color: #5f4770;
}
</style>
